import React from 'react';
import logo from './logo1.svg';
import './App.css';
import { UserForm } from './components/UserForm';
const App = () => <>
  <div className="logo-box">
    <img src={logo} className="App-logo" alt="logo" />
  </div>
  <img src={'essets/fadeout.png'} className="fadeout" alt="logo" />
  <UserForm />
</>

export default App;
