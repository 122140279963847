import React, { Component } from 'react';
import logo from './logo1.svg';
import './App.css';
import ScrollMagic from 'scrollmagic'

export class Intro extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  componentDidMount() {
    var controller = new ScrollMagic.Controller();
    var revealElements = document.getElementsByClassName("reveal1");
    for (var i = 0; i < revealElements.length; i++) { // create a scene for each element

      new ScrollMagic.Scene({
        triggerElement: revealElements[i],
        triggerHook: 0.9, // show, when scrolled 10% into view
        duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
        offset: 50 // move trigger to center of element
      })
        .setClassToggle(revealElements[i], "visible") // add class to reveal
  /*       .addIndicators({ name: "reveal1 " + (i + 1) }) // add indicators (requires plugin)
   */      .addTo(controller);


    }
  }
  render() {
    return (<>
      <div className="logo-box">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <img src={'essets/fadeout.png'} className="fadeout" alt="logo" />
      <div className="App">
        <div id="reveal-elements">
          <div className='reveal1 card'>
            <img src={'essets/Bitmap.png'} className="event-img" alt="logo" />
            <p className="event-title">4월,7월,10월 작은음악회</p>
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span>연주 </span>
              <span style={{ color: "#FA6400" }}>실력 향상</span>
              <span> 과 함께 </span>
              <span style={{ color: "#FA6400" }}>무대매너</span> <span>와</span>
            </div >
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span style={{ color: "#FA6400" }}>감상태도</span>
              <span>를 배우는 원내 행사</span>
            </div>
          </div>
          <div className='reveal1 card'>
            <img src={'essets/Bitmap1.png'} className="event-img" alt="logo" />
            <p className="event-title">2월 정기연주회</p>
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span style={{ color: "#FA6400" }}>학부모님과 함께 </span>
              <span>하는 음악 </span>
              <span style={{ color: "#FA6400" }}>발표회</span>
            </div >
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span style={{ color: "#FA6400" }}>장소: 삼모ghk트센타</span>
            </div>
          </div>
          <div className='reveal1 card'>
            <img src={'essets/Bitmap2.png'} className="event-img" alt="logo" />
            <p className="event-title">전국음악콩쿨 상위입상</p>
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span>다수의 </span>
              <span style={{ color: "#FA6400" }}>전국 학생 음악 경연대회 </span>
            </div >
            <div className="event-detail" style={{ color: "#1E71FF" }}>
              <span >참가 학생 </span>
              <span style={{ color: "#FA6400" }}>전원 상위입상!</span>
            </div>

          </div >
        </div>

        <div className='space'>.</div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>1</div>
          <div className='line1'>
            <span style={{ color: "#FA6400" }}>전문성 </span>
            <span>과 </span>
            <span style={{ color: "#FA6400" }}>경력</span>
            <span>을 갖춘</span>
          </div>
          <div className='line2'>
            <span> 강사들의 </span>
            <span style={{ color: "#FA6400" }}>1:1 맞춤 개인지도</span>
          </div>
        </div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>2</div>
          <div className='line1'>
            <span> 바이올린, 우쿨렐레, 리코더 </span>
          </div>
          <div className='line2'>
            <span style={{ color: "#FA6400" }}>무료특강</span>
          </div>
        </div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>3</div>
          <div className='line1'>
            <span style={{ color: "#FA6400" }}>마켓 데이</span><span> (맛있는</span>
          </div>
          <div className='line2'>
            <span> 간식과 풍성한 선물들)</span>
          </div>
        </div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>4</div>
          <div className='line1'>
            <span>학교 앞 </span>
            <span style={{ color: "#FA6400" }}>등하원차 운행 </span>
          </div>
          <div className='line2'>
            <span>(난향,신우) </span>
            <span style={{ color: "#FA6400" }}>도우미 동승</span>
          </div>
        </div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>5</div>
          <div className='line1'>
            <span>유치원, 어린이집 </span>
            <span style={{ color: "#FA6400" }}>픽업 </span>
          </div>
          <div className='line2'>
            <span>(꿈나라,신우,신림)</span>
          </div>
        </div>
        <div className='reveal1 feature digit'>
          <img src={'essets/bar.png'} className="bar" alt="bar" />
          <div className='num'>6</div>
          <div className='line1'>
            <span>학부모님께 등하원 출결 </span>
          </div>
          <div className='line2'>
            <span>실시간 </span><span style={{ color: "#FA6400" }}>문자 전송 </span><span>서비스</span>
          </div>
        </div>

        <div className='box'></div>
      </div >
      <br />
      <br />
      <br />
      <br />
      <br />
      <button
        color="primary"
        variant="contained"
        onClick={this.continue}
      >Continue</button>
      <br />
      <br />
      <br />

      <img src={'essets/fadeout.png'} className="fadeout1" alt="logo" />
      <div className="fixed-footer">
        <div className="container">&copy; 2020 그랜드음악학원</div>
      </div>
    </>
    );
  }
}

export default Intro;
